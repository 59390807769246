<template>
  <vs-popup class="sm:popup-w-60" title="Create Payment Voucher" :active="isActive" v-on:update:active="emitModalIsActive">
    <div>
      <!------------ errors ------------->
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>

      <!------------ header ------------->
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">No. PV</label>
          <vs-input class="w-full" value="Generate Otomatis" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Vendor Name</label>
          <vs-input class="w-full" :value="vendorName" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PV Date *</label>
          <flat-pickr class="w-full" v-model="data.tgl_pv"></flat-pickr>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PV Due Date *</label>
          <flat-pickr class="w-full" v-model="data.tgl_due"></flat-pickr>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Trx Items</label>
          <vs-input class="w-full" :value="transactions.length + ' Transactions'" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Trx Grand Total</label>
          <v-money class="w-full" :value="grandTotal" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Trx Total Paid</label>
          <v-money class="w-full" :value="grandTotalPaid" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Trx Grand Total Balance</label>
          <v-money class="w-full" :value="grandTotalBalance" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs text-success font-medium">Nilai Voucher</label>
          <vs-input class="w-full" :value="grandTotalBalance | idr" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Description *</label>
          <vs-input class="w-full" placeholder="Description" v-model="data.keterangan"/>
        </div>
      </div>

      <!------------ footer ------------->
      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Cancel</vs-button>
          <vs-button :disabled="isLoading" @click="confirmCreatePaymentVoucher">
            <span v-if="isLoading" class="animate-pulse">Creating...</span>
            <span v-if="!isLoading">Create PV</span>
          </vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import ApRepository from '@/repositories/accounting/ap-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'CreatePaymentVoucher',
  props: {
    isActive: { required: true, type: Boolean },
    transactions: { required: true, type: Array }
  },
  components: {
    ValidationErrors,
    VMoney,
    flatPickr
  },
  data () {
    return {
      errors: null,
      isLoading: false,
      data: {
        tgl_pv: moment().format('YYYY-MM-DD'),
        tgl_due: moment().format('YYYY-MM-DD'),
        keterangan: null
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        const trx = this.transactions[0]
        this.data.keterangan = `Voucher untuk pembayaran ${trx.group}`
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.transactions, trx => parseFloat(trx.total || '0'))
    },
    grandTotalPaid () {
      return _.sumBy(this.transactions, trx => parseFloat(trx.total_paid || '0'))
    },
    grandTotalBalance () {
      return _.sumBy(this.transactions, trx => parseFloat(trx.total_balance || '0'))
    },
    vendorName () {
      if (this.transactions.length < 1) return ''
      return this.transactions[0].nama_rekanan
    }
  },
  methods: {
    createPaymentVoucher () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      ApRepository.createPaymentVoucher(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        tipe: this.transactions[0].tipe_jurnal,
        id_proyek: this.transactions[0].id_proyek,
        nama_rekanan: this.vendorName,
        tgl_pv: this.data.tgl_pv,
        tgl_due: this.data.tgl_due,
        nilai_voucher: this.grandTotalBalance,
        keterangan: this.data.keterangan,
        transactions: _.map(this.transactions, trx => {
          return {
            id_trx: trx.is_group_by_trx_header ? trx.id_trx_h : trx.id_trx_d
          }
        })
      }
    },

    confirmCreatePaymentVoucher () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: 'Yakin ingin membuat payment voucher?',
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => { this.createPaymentVoucher() }
      })
    },

    onSuccess () {
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.notifySuccess('Berhasil membuat payment voucher')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
